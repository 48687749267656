//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        slides: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            activeTabIndex: 0,
            delimitersWidth: '300',
            carouselDescs: [],
            carouselInterval: 6000,
        };
    },
    computed: {
        prevIcon() {
            return this.$vuetify.rtl ? 'iconiz-chevron-right' : 'iconiz-chevron-left';
        },
        nextIcon() {
            return this.$vuetify.rtl ? 'iconiz-chevron-left' : 'iconiz-chevron-right';
        },
        direction() {
            return this.$vuetify.rtl ? 'right' : 'left';
        },
        sliderAssetBaseUrl() {
            return process.env.VUE_APP_API_STORAGE_URL;
        },
    },
    mounted() {
        this.calculateDelimitersWidth();
        window.addEventListener('resize', this.calculateDelimitersWidth);
        this.caouselDescCreator();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.calculateDelimitersWidth);
    },
    methods: {
        calculateDelimitersWidth() {
            this.delimitersWidth = this.$refs['carousel-wrapper'].clientWidth / 3;
        },
        selectedDesc(descId) {
            const index = this.slides.findIndex(({ id }) => id === descId);
            this.activeTabIndex = index;
        },
        caouselDescCreator() {
            this.carouselDescs = this.slides.map(({ id, title, description }) => ({
                id,
                title,
                description,
            }));

            if (this.slides.length > 2) {
                this.carouselDescs.unshift({
                    id: this.slides[this.slides.length - 1].id,
                    title: this.slides[this.slides.length - 1].title,
                    description: this.slides[this.slides.length - 1].description,
                });
                this.carouselDescs.push({
                    id: this.slides[0].id,
                    title: this.slides[0].title,
                    description: this.slides[0].description,
                });
            }
        },
    },
};
